<template>
  <v-container fluid>
    <v-row class="ma-3" wrap>
      <!-- Nome -->
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          label="Nome"
          placeholder="Ex.: Time 01"
          required
          outlined
          dense
          v-model="time.nome"
          data-vv-as="nome"
          persistent-hint
          :hint="errors.first('adm_times.nome')"
          :error="errors.collect('adm_times.nome').length ? true : false"
          v-validate="'required|min:3'"
          data-vv-scope="adm_times"
          data-vv-validate-on="change"
          id="nome"
          name="nome"
        ></v-text-field>
      </v-col>
      <!-- Filial -->
      <v-col cols="12" sm="6" md="6">
        <v-select
          v-model="time.id_filial"
          :items="filiais"
          :loading="loadings.loadingFilial"
          label="Filial"
          ref="filial"
          placeholder="Selecione uma filial da empresa"
          item-text="nome"
          item-value="id"
          dense
          outlined
          clearable
        ></v-select>
      </v-col>
      <!-- Coordenador -->
      <v-col cols="12" sm="6" md="6">
        <v-select
          v-model="time.id_coordenador"
          :items="coordenadores"
          :loading="loadings.loadingCoordenador"
          label="Coordenador"
          ref="filial"
          placeholder="Selecione um coordenador"
          item-text="nome"
          item-value="id"
          dense
          outlined
          clearable
        ></v-select>
      </v-col>
      <!-- setor -->
      <v-col cols="12" sm="6" md="6">
        <v-select
          label="Setor"
          :items="setores"
          :loading="loadings.loadingSetor"
          item-text="nome"
          item-value="id"
          placeholder="Selecione um setor"
          outlined
          dense
          required
          v-model="time.id_setor"
          data-vv-as="setor"
          persistent-hint
          :hint="errors.first('adm_times.setor')"
          :error="errors.collect('adm_times.setor').length ? true : false"
          v-validate="'required'"
          data-vv-scope="adm_times"
          data-vv-validate-on="change"
          id="setor"
          name="setor"
        >
        </v-select>
      </v-col>
      <!-- Descrição -->
      <v-col cols="12">
        <v-textarea
          ref="descricao"
          outlined
          dense
          label="Descrição"
          v-model="time.descricao"
          data-vv-as="descricao"
          persistent-hint
          :hint="errors.first('adm_times.descricao')"
          :error="errors.collect('adm_times.descricao').length ? true : false"
          v-validate="'required'"
          data-vv-scope="adm_times"
          data-vv-validate-on="change"
          id="descricao"
          name="descricao"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "TimesForm",
  inject: ["$validator"],
  data() {
    return {
      loadings: {
        loadingFilial: true,
        loadingCoordenador: true,
        loadingSetor: true,
      },
    };
  },
  async created() {
    const dataFilial = {
      columns: ["id", "nome"],
      validate: [
        ["status", "A"],
        ["id_empresa", this.empresa.informacoes.id],
      ],
    };
    const dataSetores = {
      columns: ["id", "nome"],
      validate: null,
    };
    const dataCoordenador = {
      columns: ["id", "nome"],
      validate: [
        ["status", "A"],
        ["coordenador", 1],
        ["id_empresa", this.empresa.informacoes.id],
      ],
    };

    await this.$store.dispatch("filiais/findByColumns", dataFilial);
    this.loadings.loadingFilial = false;

    await this.$store.dispatch("usuarios/getDataByColumn", dataCoordenador);
    this.loadings.loadingCoordenador = false;

    await this.$store.dispatch("setores/findByColumns", dataSetores);
    this.loadings.loadingSetor = false;
  },
  computed: {
    time: {
      get() {
        return this.$store.getters["times/getTime"];
      },
      set(value) {
        this.$store.dispatch("times/setTime", value);
      },
    },
    setores: {
      get() {
        return this.$store.getters["setores/getSetores"];
      },
    },
    filiais: {
      get() {
        return this.$store.getters["filiais/getFiliais"];
      },
    },
    coordenadores: {
      get() {
        return this.$store.getters["usuarios/getResponsaveis"];
      },
    },
    empresa: {
      get() {
        return this.$store.getters["empresas/getEmpresaSelecionada"];
      },
    },
  },
};
</script>

<style></style>
